import React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../ui/layout";
import TemplateViewHeader from "@views/TemplateView/components/Header";
import TemplateView from "@views/TemplateView";

import { TemplateViewQueryQuery } from "@gen/gatsby-graphql";

interface TemplateViewProps extends PageProps {
  data: TemplateViewQueryQuery;
}

const TemplateViewPage: React.FC<TemplateViewProps> = props => {
  const template = props?.data?.allTemplate?.edges?.[0]?.node?.data;

  return (
    <Layout
      header={{
        children: <TemplateViewHeader template={template} />,
      }}
      seo={{
        title: template.name,
        description: template?.description?.seoDescription,
        image:
          template?.thumbnailImages?.thumbnail?.childImageSharp?.fluid
            ?.src,
      }}
    >
      <TemplateView template={template} />
    </Layout>
  );
};

export const query = graphql`
  query TemplateViewQuery($id: String!) {
    allTemplate(filter: { id: { eq: $id } }) {
      edges {
        node {
          data {
            name
            description {
              shortDescription
              seoDescription
              bigDescription
              category
              tech
              docs
              features {
                key
                text
              }
            }
            links {
              templateUrl
            }
            price {
              currentPrice
              initialPrice
            }
            slug
            thumbnailImages {
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              phoneThumbnail {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imacThumbnail {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateViewPage;
